import "./Header.css";
import logo from "../../images/Logo.svg";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { Link } from "react-router-dom";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";

function getDate() {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    return `${month} ${day}`;
}

function Header({
    modalHandler,
    location,
    handleSignIn,
    handleLogout,
    handleSignUp,
}) {
    const { isLoggedIn, currentUser } = useContext(CurrentUserContext);

    return (
        <header className="header">
            <div className="header__left">
                <Link to="/">
                    <img src={logo} alt="wtwr" />
                </Link>
                <p className="header__text header__date">
                    {getDate()}, {location}
                </p>
            </div>
            <div className="header__right">
                {isLoggedIn ? (
                    <>
                        <ToggleSwitch />
                        <button
                            onClick={modalHandler}
                            className="header__text header__button"
                        >
                            + Add Clothes
                        </button>
                        <Link to="/profile" className="header__name header__text">
                            {currentUser.name}
                        </Link>
                        <img
                            src={currentUser.avatar}
                            alt="User Avatar"
                            className="header__avatar"
                        />
                        <button
                            onClick={handleLogout}
                            className="header__text header__button"
                        >
                            Sign Out
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            onClick={handleSignUp}
                            className="header__text header__button"
                        >
                            Sign Up
                        </button>
                        <button
                            onClick={handleSignIn}
                            className="header__text header__button"
                        >
                            Log In
                        </button>
                    </>
                )}
            </div>
        </header>
    );
}
export default Header;
