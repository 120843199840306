//export const baseUrl = "https://api.bduckwtwr.crabdance.com";
export const baseUrl = "http://localhost:3001";

const localToken = localStorage.getItem("jwt");

export async function getItems() {
    const res = await fetch(`${baseUrl}/items`);
    return processServerResponse(res);
}
export async function likeItem(id, token) {
    if (!token) return;
    const res = await fetch(`${baseUrl}/items/${id}/likes`, {
        method: "PUT",
        headers: {
            authorization: `Bearer ${localToken}`,
            "Content-Type": "application/json",
        },
    });
    return processServerResponse(res);
}
export async function unlikeItem(id, token) {
    if (!token) return;
    const res = await fetch(`${baseUrl}/items/${id}/likes`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localToken}`,
        },
    });
    return processServerResponse(res);
}
export async function addItem(item) {
    const res = await fetch(`${baseUrl}/items`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localToken}`,
        },
        body: JSON.stringify(item),
    });
    return processServerResponse(res);
}
export async function deleteItem(id) {
    if (!localToken) return;
    const res = await fetch(`${baseUrl}/items/${id}`, {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localToken}`,
        },
        method: "DELETE",
    });
    return processServerResponse(res);
}
export function processServerResponse(res) {
    if (res.ok) {
        return res.json();
    } else
        return Promise.reject(
            `oops something very very bad happened! Error: ${res.status}`,
        );
}
